import React from "react"; 
import Infrastructure from "../components/InfrastructureComp";

const InfrastructurePage = () => {
  return (
    <div>
      <Infrastructure />
    </div>
  );
};

export default InfrastructurePage;
