import React from "react"; 
import News from "../components/NewsComp";

const NewsPage = () => {
  return (
    <div>
      <News />
    </div>
  );
};

export default NewsPage;
