import React from "react";
import Gallery from "../components/GalleryComp";

const GalleryPage = () => {
  return (
    <div>
      <Gallery />
    </div>
  );
};

export default GalleryPage;
